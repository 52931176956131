export const menuItems = [
  {
    id: 1,
    label: "menuitems.menu.text",
    isTitle: true,
  },
  {
    id: 2,
    label: "Dashboard",
    icon: "fas fa-home",
    link: "/dashboard",
  },
  {
    id: 3,
    icon: "ri-video-fill",
    label: "Studio Management",
    link: "/studio",
  },
];
